<!--
 * @Descripttion: 
 * @Author: wuxinxin
 * @Date: 2022-03-19 18:59:32
 * @LastEditors: wangru
 * @LastEditTime: 2023-06-02 19:34:46
-->
<template>
  <div class="wrap">
    <div class="content-wrap">
      <img
        class="wrap-background"
        src="@/assets/img/jijiaBg.png"
        title="计价系统申请用户"
      />
      <div class="filed">
        <div class="contacts">
          <div class="info">
            <p class="tip">您的姓名*</p>

            <van-field
              :class="{ 'field-disabled': disabled }"
              v-model="contacts"
              placeholder="请输入您的姓名"
              :rules="[{ required: true, message: '请输入您的姓名' }]"
            />
            <p class="tip tipNo2">您的电话*</p>
            <van-field
              :class="{ 'field-disabled': disabled }"
              v-model="mobile"
              placeholder="请输入您的手机号码"
              type="tel"
              maxlength="11"
              :disabled="disabled"
              :error="false"
              :rules="[{ required: true, message: '请输入您的手机号码' }]"
            />
            <div class="codeMsg">
              <van-field
                :class="{ 'field-disabled': disabled }"
                v-model="mobileVerifyCode"
                :disabled="disabled"
                placeholder="请输入验证码"
                maxlength="6"
                type="tel"
              />
              <button
                class="code"
                :class="{ 'code-disabled': codeDisabled }"
                @click="start"
                type="text"
              >
                <!-- <button
                class="code"
                :class="{ 'code-disabled': codeDisabled }"
                @click="start"
                type="text"
                :disabled="codeDisabled"
              > -->
                {{ codeMsg }}
              </button>
            </div>
          </div>
          <van-button
            type="info"
            native-type="submit"
            class="apply-submit"
            :disabled="disabled"
            :loading="loading"
            @click="onSubmit"
            >立即申请
            <!-- <img class="button-icon" src="@/assets/img/button-icon.png" /> -->
          </van-button>
        </div>
      </div>
      <van-dialog
        v-model="isShowSussessModel"
        class="successModel"
        :showConfirmButton="false"
      >
        <img src="@/assets/img/sureBg.png" alt="" />
        <div class="content">
          <p class="title">恭喜您，申请试用成功！</p>
          <p class="notice">
            账号默认密码平台将通过手机短信发送给您，请注意查收。
          </p>
          <p class="sureBtn">
            <img src="@/assets/img/sure.png" alt="" @click="isSure" />
          </p>
        </div>
      </van-dialog>
      <van-dialog
        v-model="hasSuccessModel"
        class="successModel"
        :showConfirmButton="false"
      >
        <img src="@/assets/img/sureBg.png" alt="" />
        <div class="content">
          <p class="title">恭喜您，申请试用成功！</p>
          <p class="notice">
            申请成功！您当前账户已在平台注册，请前往官网登录体验。
          </p>
          <p class="sureBtn">
            <img src="@/assets/img/sure.png" alt="" @click="isSure" />
          </p>
        </div>
      </van-dialog>
      <van-dialog
        v-model="isShowIssueModel"
        class="successModel"
        :showConfirmButton="false"
      >
        <img src="@/assets/img/cancelBg.png" alt="" />
        <div class="content">
          <p class="title noline">
            系统检测您当前申请账号 已存在以下企业归属：
          </p>
          <div class="companys">
            <p class="company" v-for="item of companyList" :key="item.index">
              {{ item.name }}
            </p>
          </div>
          <p class="notice">是否继续申请试用？</p>
          <p class="btns">
            <img
              src="@/assets/img/cancel.png"
              alt=""
              @click="isShowIssueModel = false"
            />
            <img
              src="@/assets/img/sure.png"
              alt=""
              @click="isShowIssueModel = false"
            />
          </p>
        </div>
      </van-dialog>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Notify } from 'vant';
import 'vant/lib/button/style';
import * as Api from '@/api/tryOut.ts';
export default Vue.extend({
  data() {
    return {
      isShowSussessModel: false, //申请成功弹框
      hasSuccessModel: false,
      isShowIssueModel: false, //申请有问题
      companyList: [
        { index: 1, name: '西安百达科技' },
        { index: 2, name: '西安西力' },
      ],
      show: false,
      mobile: '',
      contacts: '',
      mobileVerifyCode: '',
      codeDisabled: true,
      codeMsg: '获取验证码 ',
      countdown: 60,
      timer: null,
      dialogText: '',
      urlShow: false,
      buttonText: '',
      disabled: false,
      loading: false,
    };
  },
  mounted() {
    this.getToken();
  },
  created() {
    document.title = '试用申请';
  },
  beforeDestroy() {
    window.sessionStorage.removeItem('isSubmitToken');
  },
  //监听
  watch: {
    mobile: {
      handler(newName) {
        var mobile = /^[1][3,4,5,7,8,9][0-9]{9}$/.test(newName);
        if (mobile === false && mobile !== '') {
          this.codeDisabled = true;
        } else {
          this.codeDisabled = false;
        }
      },
    },
  },
  methods: {
    /**
     * @description: 获取token
     * @param {*}
     * @return {number}
     */
    getToken() {
      Api.submitToken().then((res) => {
        if (res) {
          window.localStorage.setItem('submitToken', res.submitToken);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @description: 表单验证提交
     * @param {*}
     * @return {number}
     */
    onSubmit() {
      window.sessionStorage.setItem('isSubmitToken', 'exit');
      if (this.contacts.length === 0) {
        Notify('请输入姓名');
        return;
      } else if (!/^[a-zA-Z\u4e00-\u9fa5]{2,10}$/.test(this.contacts)) {
        Notify('姓名由2-10位字母或汉字组成');
        return;
      }
      if (this.mobile.length === 0) {
        Notify('请输入手机号');
        return;
      } else if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.mobile)) {
        Notify('请输入正确的手机号码');
        return;
      }
      if (this.mobileVerifyCode === '') {
        Notify('请输入验证码');
        return;
      }

      const param = {
        mobile: this.mobile,
        mobileVerifyCode: this.mobileVerifyCode,
        nickName: this.contacts,
      };
      this.loading = true;
      Api.newCoupons_receive(param)
        .then((res) => {
          if (res === 0) {
            this.loading = false;
            this.isShowSussessModel = true;
            // this.hasSuccessModel = true;
          } else if (res === 1) {
            // this.getToken();
            // this.$message.error(res.message);
            this.hasSuccessModel = true;
            this.loading = false;
          }
        })
        .finally(() => {
          this.getToken();
          this.loading = false;
        });
      // }
    },
    /**
     * @description: 表单清空
     * @param {*}
     * @return {number}
     */
    reset() {
      this.mobile = '';
      this.mobileVerifyCode = '';
      this.contacts = '';
      clearInterval(this.timer);
      this.codeMsg = '获取验证码';
      this.countdown = 60;
      this.timer = null;
      this.codeDisabled = false;
    },
    /**
     * @description: 弹框关闭，表单清空
     * @param {*}
     * @return {number}
     */
    dialogClose() {
      this.show = false;
      this.reset();
    },
    /**
     * @description: 获取验证码
     * @param {*}
     * @return {number}
     */
    start() {
      if (
        this.mobile.length > 0 &&
        !/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.mobile)
      ) {
        Notify('请输入正确的手机号码');
        return;
      }
      window.sessionStorage.setItem('isSubmitToken', 'NoExit');
      if (this.mobile !== '') {
        Api.receiveNewCouponVerifyCode(this.mobile).then((res) => {
          if (res) {
            this.codeDisabled = true;
            if (!this.timer) {
              this.timer = setInterval(() => {
                if (this.countdown > 0 && this.countdown <= 60) {
                  this.countdown--;
                  if (this.countdown !== 0) {
                    this.codeDisabled = true;
                    this.codeMsg = '重新发送(' + this.countdown + ')';
                  } else {
                    clearInterval(this.timer);
                    this.codeMsg = '获取验证码';
                    this.countdown = 60;
                    this.timer = null;
                    this.codeDisabled = false;
                  }
                }
              }, 1000);
            }
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.codeDisabled = false;
      }
    },
    /**
     * @description: 弹框关闭表单重置
     * @param {*}
     * @return {number}
     */
    isSure() {
      this.isShowSussessModel = false;
      this.hasSuccessModel = false;
      this.reset();
    },
  },
});
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  .content-wrap {
    overflow-y: auto;
    position: relative;
    .wrap-background {
      width: 100%;
    }
    .footer-wrap {
      margin-top: -6px;
    }
    .filed {
      position: absolute;
      top: 31%;
      left: 8%;
      width: 85%;
      .contacts {
        padding: 20px 30px 10px;
        .info {
          margin: 10px 0 0;
          .tip {
            font-size: 15px;
            font-weight: bold;
            color: #d5a508;
            letter-spacing: 2px;
            margin-bottom: 2px !important;
          }
          .tipNo2 {
            margin-top: 8px;
          }
          .codeMsg {
            padding: 8px 0px 0px;
            display: flex;
            flex-direction: row;
            position: relative;
            .code {
              position: absolute;
              right: 0;
              margin-top: 2px;
              height: 29px;
              white-space: nowrap;
              padding: 3px 10px;
              font-size: 15px;
              font-weight: 600;
              color: white;
              background: #52ad31;
              border: #ffffff;
              border-radius: 0 6px 6px 0;
            }
            .code-disabled {
              color: #c8cddb;
            }
          }
        }
        .apply-submit {
          position: absolute;
          bottom: -35px;
          left: 30%;
          height: 35px;
          width: 35%;
          font-weight: 900;
          background: linear-gradient(180deg, #9bab2d 0%, #c8a616 100%);
          border-radius: 39px;
        }
      }
    }
    .successModel {
      position: fixed;
      top: 50%;
      left: 50%;
      .content {
        position: absolute;
        top: 0;
        left: 0;
        color: #ffd109;
        width: 94%;
        height: 92%;
        bottom: 0;
        right: 0;
        margin: auto;
        border: 1px solid rgba(255, 209, 9, 0.5);
        border-radius: 7px;
        .title {
          width: 90%;
          margin: auto;
          padding: 30px 0 15px;
          text-align: center;
          font-weight: bold;
          border-bottom: 1px solid rgba(255, 209, 9, 0.3);
        }
        .noline {
          border-bottom: none;
          width: 70%;
        }
        .companys {
          max-height: 25%;
          overflow-y: auto;
          .company {
            text-align: center;
            margin: 3px 0 !important;
          }
        }

        .btns {
          display: flex;
          justify-content: space-evenly;
        }
        .notice {
          width: 80%;
          margin: 15px auto;
          font-size: 14px;
          text-align: center;
          opacity: 0.7;
        }
        .sureBtn img {
          display: block;
          margin: 30px auto;
        }
      }
    }
  }
}
</style>
<style>
.van-field__control {
  font-size: 14px;
  font-weight: bold;
  color: #d5a508 !important;
  letter-spacing: 2px;
  outline: none;
}
::placeholder {
  color: #d5a508 !important;
  opacity: 0.6;
}
.van-cell {
  line-height: 25px;
  padding: 2px 15px;
  border-radius: 6px;
  border: 2px solid #3b9a46;
  background-color: transparent;
}
.van-dialog {
  width: auto;
}
.ant-message {
  z-index: 3000 !important;
}
.van-cell:after {
  border: none;
}
</style>
